import React from "react";

const component = () => {
  return (
    <div className="project__list">
      <h2 style={{ color: "orange" }}>Projects</h2>
      <hr />
      <ul typeof="1">
        <li className="project__list__item">
          <a target="blank" className="project__list_link" href="https://ojha-weather-app.herokuapp.com/">
            Weather App
          </a>
        </li>
        <li className="project__list__item">
          <a target="blank" className="project__list_link" href="https://random-scrum.vercel.app/">
            Today's Random Scrum
          </a>
        </li>
        <li className="project__list__item">
          <a target="blank" className="project__list_link" href="https://covid-status-check.vercel.app/">
            Covin Vaccine Availability check
          </a>
        </li>
        <li className="project__list__item">
          <a
            target="blank"
            className="project__list_link"
            href="https://github.com/rojha-mdsol/covid-vaccine-availability-lambda"
          >
            Covin Vaccine Availability Notification in mail through lambda
          </a>
        </li>
        <li className="project__list__item">
          <a target="blank" className="project__list_link" href="https://cssexamples.vercel.app/">
            CSS Projects
          </a>
        </li>
      </ul>
    </div>
  );
};

export default component;
