import React from "react";

const Description = (props) => {
  return (
    <div className="description">
      <h2>Who am i?</h2>
      <hr /> I am a Software Engineer with 6+ Years of experience.
      <br /> <br />I am working Javascript, React, HTML and CSS for past 4 Years.
      <br /> <br /> Here i am going to list all of my projects which i did while learning from Udemy, Pluralsight,
      Youtube, Medium, other blogs and new stuffs.
    </div>
  );
};

export default Description;
