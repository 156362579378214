import React from "react";

const Profile = () => {
  return (
    <div className="portfolio text-center">
      <div className="profile__name__cv">
        <h2 className="profile__name">Ravi Ojha</h2>
        <a
          title="My Resume"
          className="profile__cv"
          href="https://drive.google.com/uc?export=download&id=1IbdYag7bUs_f_CYGEFtPelugtoGkqq__"
        >
          <img className="profile__cv__link__image" src="link.svg" alt="My Resume"></img>
        </a>
      </div>
      <hr />
      <div>Web Engineer</div>
      <div>Previously @verizon, @oracle and @modelogiq.</div>
      <div className="profile__visual_images">
        <a target="blank" href="https://github.com/rojha-mdsol">
          <img className="profile__visual_image_github" src="github.png" alt="github"></img>
        </a>
        <a target="blank" href="https://www.linkedin.com/in/ravi-ojha/">
          <img className="profile__visual_image_linkedin" src="linkedin.png" alt="linkedin"></img>
        </a>
      </div>
    </div>
  );
};

Profile.propTypes = {};

export default Profile;
