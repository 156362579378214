import React from "react";
import Description from "./components/Description";
import Profile from "./components/Profile/component";
import Projects from "./components/Projects";

import "./App.css";

// TODO: Add Unsplash Note

function App() {
  const bg = "raviojha.jpeg";
  return (
    <>
      <div className="main___profile">
        <img src={bg} alt="Ravi Ojha" className="main___profile_image" />
        <Profile />
      </div>
      <div className="main__container">
        <Description />
        <Projects />
      </div>
    </>
  );
}

export default App;
